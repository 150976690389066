
<template>
  <v-row>
    <v-col cols="3" md="3"
      style="max-height: 600px"
      class="overflow-y-auto"
    >
      <v-card class="pa-2" outlined tile>
        Filter Pasien
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select
            v-model="instalasiSelected"
            :hint="`${instalasiSelected.value}, ${instalasiSelected.text}`"
            :items="instalasi"
            item-text="text"
            item-value="value"
            label="Instalasi"
            persistent-hint
            return-object
            single-line
            dense
            outlined
            @change="layananChange"
          ></v-select>
          <v-menu
            v-model="showDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field label="Tanggal" readonly :value="tanggal" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              locale="en-in"
              v-model="tanggal"
              no-title
              :min="tanggalMin"
              :max="tanggalMax"
              @input="showDateMenu = false"
              @change="layananChange"
            ></v-date-picker>
          </v-menu>
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="filter">Filter</v-btn>
        </v-form>
      </v-card>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          dense
          v-if="$store.state.loading"
          item-key="name"
          class="elevation-1"
          loading
          loading-text="Loading..."
        ></v-data-table>
        <div class="table-pasien">
          <v-data-table 
            dense 
            :headers="tablePasienHeaders" 
            :items="pasien" 
            :search="search"
            @click:row="lihatPasien(pasien.sep)"
          >
            <template v-slot:item="{ item }">
              <tr @click="lihatPasien(item)" :class="item.sudahKlaim?'green':''">
                  <td>{{item.no}}</td>
                  <td>{{item.sep}}</td>
                  <!-- <td>{{item.sep}}</td> -->
                  <td>{{item.nama}}</td>
              </tr>
          </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>


 
    <v-col cols="9" md="9">
      <v-card>
        <v-simple-table dense >
          <template v-slot:default>
            <tbody>
              <tr>
                <td><b>No Reg</b> : {{ kd_register }}</td>
                <td><b>No RM</b> : {{ noRM }}</td>
                <td><b>Penjamin</b> : {{ penjamin }}</td>
              </tr>
              <tr>
                <td><b>No SEP</b> : {{ noSep }}</td>
                <td><b>Tgl. Reg</b> : {{ tglReg }}</td>
                <td><b>Perawatan</b> : {{ perawatan }}</td>
              </tr>
              <tr>
                <td><b>Nama</b> : {{ namaPasien }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-left">
          <v-btn
            small
            class="ma-1"
            color="primary"
            v-for="item in dataKlaims"
            :key="item.id"
            @click="lihat(item.kode)"
          >
            <span v-if="item.kode=='BPPS'">
              <span v-if="badgePesan">
                <v-badge
                  color="green"
                  content="Baru"
                >
                {{ item.nama }}
                </v-badge>
              </span>
              <span v-else>
                {{ item.nama }}
              </span>
            </span>
            <span v-else>
              {{ item.nama }}
            </span>
          </v-btn>
        </div>
        <hr>
        <!-- Inacbg -->
        <div v-if="menu_klaim.BPIN">
          <iframe width="100%" height="500px" :src="inacbg_src"></iframe>
        </div>

        <!-- sep -->
        <div v-if="menu_klaim.BPSP">
            <table id="kop" cellspacing="0" cellpadding="0">
              <tbody>
                  <tr>
                      <td><img src="@/assets/logo-bpjs.png" style="width: 300px;"/></td>
                      <td style="text-align:center;">
                          <span style="font-size: 11pt;">SURAT ELIGIBILITAS PESERTA (SEP)</span>
                          <br>
                          <span style="font-size: 11pt;">RSUD dr. SOESELO SLAWI</span>
                      </td>
                      <td>&nbsp;</td> 
                  </tr>
                </tbody>
            </table>
          <table id="header" cellspacing="0" cellpadding="0">
                  <tr>
                      <th>No SEP</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_NO_SEP}} (MR: {{sep.FS_MR}})</td>
                      <td></td>
                      <th>PRB</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td></td>
                  </tr>
                  <tr>
                      <th>Tgl SEP</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FD_TGL_SEP}} (Sex: {{sep.KELAMIN}})</td>
                      <td></td>
                      <th>Jenis Peserta</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_JNS_PESERTA}}</td>
                  </tr>
                  <tr>
                      <th>No Kartu</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_NO_PESERTA}}</td>
                      <td></td>
                      <th>COB</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td></td>
                  </tr>
                  <tr>
                      <th>Nama Peserta</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_NM_PASIEN}}</td>
                      <td></td>
                      <th>Jenis Rawat</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.pelayanannama}}</td>
                  </tr>
                  <tr>
                      <th>Tgl. Lahir</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FD_TGL_LAHIR}}</td>
                      <td></td>
                      <th>Kelas Rawat</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td></td>
                  </tr>
                  <tr>
                      <th>No.Telepon</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_NO_TELP}}</td>
                      <td></td>
                      <th>Penjamin</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.PENJAMIN}}</td>
                  </tr>
                  <tr>
                      <th>Sub/Spesialis</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_NM_LAYANAN}}</td>
                      <td></td>
                      <th></th>
                      <td width="30px" style="text-align:center"></td>
                      <td></td>
                  </tr>
                  <tr>
                      <th>Faskes Perujuk</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.provPerujukkode}} - {{sep.provPerujuknama}}</td>
                      <td></td>
                      <th></th>
                      <td width="30px" style="text-align:center"></td>
                      <td></td>
                  </tr> 
                  <tr>
                      <th>Diagnosa Awal</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.diagnosakode}} - {{sep.diagnosanama}}</td>
                      <td></td>
                      <th></th>
                      <td width="30px" style="text-align:center"></td>
                      <td></td>
                  </tr>
                  <tr>
                      <th>Catatan</th>
                      <td width="30px" style="text-align:center">:</td>
                      <td>{{sep.FS_CATATAN}}</td>
                      <td></td>
                      <th></th>
                      <td width="30px" style="text-align:center"></td>
                      <td></td>
                  </tr>
          </table>
          <table id="bottom" cellspacing="0" cellpadding="0">
              <tbody>
                  <tr>
                      <td>No. REG : {{sep.FS_KD_REG}}</td>
                      <td>No. RUJUKAN : {{sep.FS_NO_RUJUKAN}}</td>
                      <td>No. RM : {{sep.FS_MR}}</td>
                  </tr>
              </tbody>
          </table>
      </div>

      <!-- transaksi -->
      <div v-if="menu_klaim.BPTR">
        <v-simple-table dense >
          <template v-slot:default>
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Keterangan</th>
                <th>Biaya</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in transaksis"
                :key="item.fs_kd_trs"
              >
                <td>{{ item.fd_tgl_trs }}</td>
                <td>{{ item.fs_keterangan }}</td>
                <td style="text-align: right">{{ item.fn_total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
          
      <!-- CPPT -->
      <div v-if="menu_klaim.BPCP">
        <div v-if="cppts.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-simple-table dense v-else-if="cppts.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Bagian</th>
                <th>Tanggal</th>
                <th>Subjektif</th>
                <th>Objektif</th>
                <th>Assesment</th>
                <th>Planning</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody>                    
              <tr
                v-for="item in cppts"
                :key="item.id_hasil_cppt"
              >
                <td>{{ item.fs_nm_layanan }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.subyektif }}</td>
                <td>
                  <div v-if="item.tipe=='ASSESMEN'">
                    BB : {{ item.berat_badan }}, Tekanan Darah : {{ item.tekanan_darah }} x/mnt, Frek Nadi : {{ item.frek_nadi }}
                  </div>
                  <div v-else>
                    {{ item.obyektif }}
                  </div>
                </td>
                <td>{{ item.assessment }}</td>
                <td>{{ item.plan }}</td>
                <td>{{ item.keterangan }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        
        <div class="upload" style="border:1px solid black; padding:5px" v-if="Object.keys(fileCppt).length === 0 && fileCppt.constructor === Object">
        </div>
        <div v-else>
          <hr>
          <br>
           <div class="text-h6">
              File Upload CPPT
            </div>
          <iframe v-if="fileCppt.type=='pdf'" :src="fileCppt.base64+'#toolbar=0'" width="100%" height="500px"></iframe>
          <img v-else :src="fileCppt.base64" width="100%" />
        </div>
      </div>

          <!-- Resep -->
      <div v-if="menu_klaim.BPRP">
        <div v-if="obats.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>NO</th>
                <th>NAMA OBAT</th>
                <th>JUMLAH</th>
                <th>SIGNA</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in obats"
                :key="item.name"
              >
                <td>{{ item.no }}</td>
                <td>{{ item.nama }}</td>
                <td>{{ item.jumlah }}</td>
                <td>{{ item.satuan }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="upload" style="border:1px solid black; padding:5px" v-if="Object.keys(fileResep).length === 0 && fileResep.constructor === Object">
        </div>
        <div v-else>
          <hr>
          <br>
           <div class="text-h6">
              File Upload RESEP
            </div>
          <iframe v-if="fileResep.type=='pdf'" :src="fileResep.base64+'#toolbar=0'" width="100%" height="500px"></iframe>
          <img v-else :src="fileResep.base64" width="100%" />
        </div>
      </div>

          <!-- Laborat -->
      <div v-if="menu_klaim.BPLA">
        <div v-if="laborats.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Pemeriksaan</th>
                <th></th>
                <th>Hasil</th>
                <th>Satuan</th>
                <th>Nilai Rujukan</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in laborats"
                :key="item.NO"
              >
                <td>{{ item.REQUEST_DT }}</td>
                <td>{{ item.TEST_NAME }}</td>
                <td>{{ item.FLAG }}</td>
                <td>{{ item.RESULT_VALUE2 }}</td>
                <td>{{ item.UNIT }}</td>
                <td>{{ item.REF_RANGE }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

          <!-- Radiologi -->
      <div v-if="menu_klaim.BPRA">
        <div v-if="radiologis.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>No</th>
                <th>Nama pemeriksaan</th>
                <th>Hasil Pemeriksaan</th>
              </tr>
            </thead>
            <tbody>
              
              <tr
                v-for="item in radiologis"
                :key="item.NO"
              >
                <td>{{ item.NO }}</td>
                <td>{{ item.nama_pemeriksaan }}</td>
                <td>{{ item.hasil_pemeriksaan }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="upload" style="border:1px solid black; padding:5px" v-if="Object.keys(fileRadiologi).length === 0 && fileRadiologi.constructor === Object">
        </div>
        <div v-else>
          <hr>
          <br>
           <div class="text-h6">
              File Upload RADIOLOGI
            </div>
          <iframe v-if="fileRadiologi.type=='pdf'" :src="fileRadiologi.base64+'#toolbar=0'" width="100%" height="500px"></iframe>
          <img v-else :src="fileRadiologi.base64" width="100%" />
        </div>
      </div>

          <!-- ICD 10 -->
      <div v-if="menu_klaim.BP10">
        <div v-if="icd10s.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Kode</th>
                <th>Nama</th>
                <th>Jenis</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in icd10s"
                :key="item.name"
              >
                <!-- <td style="text-align: center">{{ item.no }}</td> -->
                <td>{{ item.hasil_icd }}</td>
                <td>{{ item.fs_nm_icd }}</td>
                <td>{{ item.jenis }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <!-- ICD 9 -->
      <div v-if="menu_klaim.BP09">
        <div v-if="icd9s.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Kode</th>
                <th>Nama</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in icd9s"
                :key="item.name"
              >
                <td>{{ item.hasil_icd9 }}</td>
                <td>{{ item.fs_nm_icd9cm }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <!-- Penunjang lain -->
      <div v-if="menu_klaim.BPPA">
        <div v-if="penunjanglains.length == 0">
          <br>
          <v-alert
            dense
            type="info"
          >
            Data tidak ditemukan
          </v-alert>
        </div>
        <v-list>
          <v-list-group
            v-for="item in penunjanglains"
            :key="item.no"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{item.no}}). {{item.fs_nm_layanan}} - {{item.jenis_penunjang}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-content>
              <p class="text-left">
                Catatan: {{item.catatan}}
              </p>
              <div style="padding:0 5px; border:1px solid black;">
                <iframe v-if="item.type=='pdf'" :src="item.base64+'#toolbar=0'" width="100%" height="500px"></iframe>
                <img v-else :src="item.base64" width="100%" />
              </div>
            </v-list-item-content>
          </v-list-group>
        </v-list>
      </div>

      <!-- Pesan -->
      <v-container class="py-4" v-if="menu_klaim.BPPS">
        <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;" ref="container_pesan">
          
          <div v-for="item in pesans" :key="item.id">
            <div v-show="show">
              <center v-if="item.showPesanBaru" style="position: absolute; z-index: 1000; left: 45%; top: 150px">
                <!-- <v-chip color="green" text-color="white" :href="'#pesan_'+item.id" @click="showPesanBaru(item.id)"> -->
                <v-chip color="green" text-color="white" @click="showPesanBaru(item.id)">
                Pesan baru <v-icon>mdi-arrow-down</v-icon>
                </v-chip>
              </center>
            </div>

            <v-row v-if="item.fs_kd_peg != user.fs_kd_peg" justify="start" :id="'pesan_'+item.id">
              <v-col lg="1" md="1" sm="1" class="d-flex justify-center">
                <v-avatar size="48" class="mx-auto">
                  <v-icon x-large v-if="item.foto_profil=='' || item.foto_profil==null" >mdi-account-circle</v-icon>
                  <v-img v-else :src="item.foto_profil"></v-img>
                </v-avatar>
              </v-col>
              <v-col lg="9" md="9" sm="9">
                <v-card class="friend_message">
                  <p class="text-subtitle-2 font-weight-bold" style="margin-bottom:0">{{item.nama_lengkap}} - {{item.karyawan}}</p>
                    {{item.pesan}}
                    <br/><span class="font-italic" style="font-size:0.7rem">{{item.created_at}}</span>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-else justify="end" :id="'pesan_'+item.id">
              <v-col lg="9" md="9" sm="9">
                <v-card class="my_message">
                  <!-- <p class="text-subtitle-2 font-weight-bold" style="margin-bottom:0">{{item.nama_lengkap}} - {{item.karyawan}}</p> -->
                  {{item.pesan}}
                  <br/><span class="font-italic" style="font-size:0.7rem">{{item.created_at}}</span>
                </v-card>
              </v-col>
              <v-col lg="1" md="1" sm="1" class="d-flex justify-center">
                <v-avatar size="48" class="mx-auto">
                  <v-icon x-large  v-if="item.foto_profil=='' || item.foto_profil==null" >mdi-account-circle</v-icon>
                  <v-img v-else :src="item.foto_profil"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </div>
          
          <div id="last_messsage"></div>
        </div>
        <v-form>
          <v-row>
            <v-col cols="12" lg="10" md="10" sm="10">
              <v-text-field
                v-model="pesan"
                label="Tulis pesan"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="2" style="padding-top: 25px;">
              <v-btn
                color="primary"
                @click="kirim"
                dense
              >
                <v-icon>mdi-send</v-icon> Kirim
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      
      <hr v-if="kd_register!='-'" style="margin-top: 20px; margin-bottom:0">
      <div v-if="kd_register!='-'" >
        <v-checkbox v-model="check_sudahklaim" @change="dokumenChange()">
          <template v-slot:label>
            <div class="font-weight-bold">
              Setujui dokumen klaim pasien ini
            </div>
          </template>
        </v-checkbox>
      </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
    table{
        width:100%;
        margin-top: 5px;
    }
    table tr th{text-align: left;}
    #kop{
        border-bottom: 2px solid black;
    }
    #header{
        padding-bottom: 5px;
        margin-top: 5px;
    }
    #header tr td{
        padding-bottom: 2px;
    }
    #pasien{
        padding-bottom: 5px;
        border-bottom: 1px solid black;
        margin-top: 5px;

    }
    #pasien tr td{
        padding-bottom: 2px;
    }
    #diagnosa tr td{
        padding-bottom: 2px;
    }
    /* diagnosa2 */
    #hasil_grouping tr td{
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .uang{
        text-align:right;
    }
    .meta_data{
        font-size:10px;
        font-style: italic;
        padding-top: 2px;
    }


    .example-drag label.btn {
      margin-bottom: 0;
      margin-right: 1rem;
    }
    .example-drag .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: .6;
      text-align: center;
      background: #000;
    }
    .example-drag .drop-active h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }

    .btn-success {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }
    .btn-primary {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
    .btn-danger {
      color: #fff;
      background-color: #bd2130;
      border-color: #b21f2d;
    }
    .btn {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      padding: .1rem .5rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: .25rem;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      margin: 0 5px;
  }
  div.table-pasien table tr td:hover {
    cursor: pointer;
  }
</style>
<script>
import Pusher from 'pusher-js'

export default {
  name: "Index",
  components: {
  },
  mounted() {

  },
  created() {
    this.$store.dispatch("checkIzinAkses",{
      namaMenu: 'Persetujuan',
    });
    this.$store.dispatch("getAllLayanan");
    this.$store.dispatch("getDataKlaim");
    this.$store.dispatch("getSettings");
  },
  computed: {
    user(){
      return this.$store.getters.user;
    },
    tanggal: {
      get: function () {
        if (this.tanggalPicker=='') {
          return this.tanggalMin
        } else {
          return this.tanggalPicker
        }
      },
      set: function (newValue) {
        this.tanggalPicker= newValue
      }
    },
    pasien() {
      return this.$store.getters.pasiens;
    },
    dataKlaims() {
      return this.$store.getters.dataKlaims;
    },
    sep() {
      return this.$store.getters.sep;
    },
    obats() {
      return this.$store.getters.obats;
    },
    icd10s() {
      return this.$store.getters.icd10s;
    },
    icd9s() {
      return this.$store.getters.icd9s;
    },
    cppts() {
      return this.$store.getters.cppts;
    },
    transaksis(){
      return this.$store.getters.transaksis;
    },
    laborats(){
      return this.$store.getters.laborats;
    },
    radiologis(){
      return this.$store.getters.radiologis;
    },
    api_url(){
      return this.$store.getters.api_url;
    },
    token(){
      return this.$store.getters.token;
    },
    fileCppt(){
      return this.$store.getters.fileCppt;
    },
    fileResep(){
      return this.$store.getters.fileResep;
    },
    fileRadiologi(){
      return this.$store.getters.fileRadiologi;
    },
    penunjanglains(){
      return this.$store.getters.penunjanglains;
    },
    tanggalMin(){
      let tanggal='';
      if (this.$store.getters.settings && this.$store.getters.settings.length > 0) {
        this.$store.getters.settings.forEach(element => {
          if (element.jenis == 'bulanKlaim') {
            tanggal= element.tanggalMin
          }
        });
      }
      return tanggal
    },
    tanggalMax(){
      let tanggal='';
      if (this.$store.getters.settings && this.$store.getters.settings.length > 0) {
        this.$store.getters.settings.forEach(element => {
          if (element.jenis == 'bulanKlaim') {
            tanggal= element.tanggalMax
          }
        });
      }
      return tanggal
    },
    pesans(){
      return this.$store.getters.pesans;
    }
  },
  data() {
    return {      
      valid: true,
      showDateMenu: false,
      tanggalPicker: '',
      tablePasienHeaders: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "no",
        },
        {
          text: "No SEP",
          align: "start",
          sortable: false,
          value: "sep",
        },
        {
          text: "Nama",
          align: "start",
          sortable: false,
          value: "nama",
        },
      ],
      search: "",

      instalasiSelected: { text: 'Rawat Jalan', value: 'irj' },
      instalasi: [
        { text: 'Rawat Jalan', value: 'irj' },
        { text: 'Rawat Inap', value: 'iri' },
      ],

      kd_register: '-',
      noRM: '-',
      penjamin: '-',
      noSep: '-',
      tglReg: '-',
      perawatan: '-',
      namaPasien: '-',

      menu_klaim:{
        BPIN: false,
        BPSP: false,
        BPTR: false,
        BPLA: false,
        BPOP: false,
        BP10: false,
        BP09: false,
        BP13: false,
        BP14: false,
        BPCP: false,
        BPRA: false,
        BPLK: false,
        BPRP: false,
        BPPA: false,
        BPPS: false,
      },
      inacbg_src:'',
      check_sudahklaim: false,

      pesan: '',
      show:true,
      badgePesan:false,
    }
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    filter() {
      this.$refs.form.validate();
      this.$store.dispatch("filterPasien_v2", {
        instalasi: this.instalasiSelected.value,
        tanggal: this.tanggal,
      });
    },
    deleteItem(item) {
      confirm("Apakah yakin akan menghapus?") &&
        this.$store.dispatch("deleteKondangan", item.id);
    },
    lihatPasien(pasien){
      if (pasien.kd_register == '-' || pasien.kd_register == '') {
        alert('Pilih pasien terlebih dahulu')
        return false
      }
      let ini = this
      for (const property in this.menu_klaim) {
        ini.menu_klaim.[property] = false
      }
      // console.log(sep);
      this.kd_register= pasien.kd_register
      this.noRM= pasien.mr
      this.penjamin= pasien.jaminan
      this.noSep= pasien.sep
      this.tglReg= pasien.tgl_reg
      this.perawatan= pasien.instalasi
      this.namaPasien= pasien.nama
      this.check_sudahklaim= pasien.sudahKlaim

      this.$store.dispatch("checkReadedPesans",{
        kd_register: this.kd_register,
      }).then(response => {
          if (response.error) {
            this.badgePesan=false
          } else {
            this.badgePesan=true
          }
      }, error => {
        alert(error)
      })
      this.subscribe()

    },
    lihat(kode){
      if (this.kd_register == '-') {
        alert('Pilih pasien terlebih dahulu')
        return false
      }
      else if(this.noSep == '' || this.noSep == '-' || this.noSep == null) {
        alert('No SEP Pasien ini belum ada atau belum disinkronkan dengan aplikasi bridging')
        return false
      }
      else{
          let ini = this
          for (const property in this.menu_klaim) {
            ini.menu_klaim.[property] = false
          }
          ini.menu_klaim.[kode] = true
      }
      let kode_regis= this.kd_register;
      
      if (kode == 'BPSP') {
        this.$store.dispatch("getSep",{
          kd_register: kode_regis,
        });
      }else if (kode == 'BPIN') {
        this.inacbg_src= process.env.VUE_APP_API_ROOT+'/Api/Pemeriksaan/inacbgPdf/'+this.$store.state.token+'/'+kode_regis+'#toolbar=0'
      }else if (kode == 'BPTR') {
        this.$store.dispatch("getTransaksi",{
          kd_register: kode_regis,
        });
      }else if (kode == 'BPCP') {
        this.$store.dispatch("getCppt",{
          kd_register: kode_regis,
        });
        this.$store.dispatch("getFileCppt",{
          kd_register: kode_regis,
          jenis: 'CPPT',
        });
      }else if (kode == 'BPRA') {
        this.$store.dispatch("getRadiologi",{
          kd_register: kode_regis,
        });
         this.$store.dispatch("getFileRadiologi",{
          kd_register: kode_regis,
          jenis: 'RADIOLOGI',
        });
      }else if (kode == 'BPLA') {
        this.$store.dispatch("getLaborat",{
          no_rm: this.noRM,
          tanggal: this.tglReg,
        });
        
      }else if (kode == 'BPRP') {
        this.$store.dispatch("getObat",{
          kd_register: kode_regis,
        });
        this.$store.dispatch("getFileResep",{
          kd_register: kode_regis,
          jenis: 'RESEP',
        });
      }else if (kode == 'BP10') {
        this.$store.dispatch("getICD10",{
          kd_register: kode_regis,
        });
      }else if (kode == 'BP09') {
        this.$store.dispatch("getICD9",{
          kd_register: kode_regis,
        });
      }else if (kode == 'BPPA') {
        this.$store.dispatch("getPenunjanglains",{
          kd_register: kode_regis,
        });
      }else if (kode == 'BPPS') {
        this.$store.dispatch("getPesans",{
          kd_register: kode_regis,
        });
      }
    },
    dokumenChange(){
      if (this.check_sudahklaim) {
        this.$store.dispatch("acceptClaim",{
          no_sep: this.noSep,
          kd_register: this.kd_register,
          instalasi: this.instalasiSelected.value,
        }).then(response => {
            // console.log(response)
            if (response.error) {
              alert(response.message)
            } else {
              this.$store.dispatch("filterPasien_v2", {
                instalasi: this.instalasiSelected.value,
                tanggal: this.tanggal,
              });
            }
        }, error => {
          alert(error)

        })
      } else {
        this.$store.dispatch("cancelAcceptedClaim",{
          no_sep: this.noSep,
          kd_register: this.kd_register,
          instalasi: this.instalasiSelected.value,
        }).then(response => {
            // console.log(response)
            if (response.error) {
              alert(response.message)
            } else {
              this.$store.dispatch("filterPasien_v2", {
                instalasi: this.instalasiSelected.value,
                tanggal: this.tanggal,
              });
            }
        }, error => {
          alert(error)

        })
      }
    },
    
    kirim(){
      if (this.pesan=='') {
        alert('Pesan tidak boleh kosong')
      }
      else{
        this.$store.dispatch("storePesan",{
          kd_register: this.kd_register,
          pesan: this.pesan,
        }).then(response => {
            if (response.error) {
              alert(response.message)
            } else {
              this.pesan=''
            }
        }, error => {
          alert(error)
        })
      }
    },
    scrollToEnd() {
      // var content = this.$refs.container_pesan;
      // content.scrollTop = content.scrollHeight;
      // content.scrollTop = 500;
      // content.scrollTop = 0;
      // console.log(content.scrollHeight);
      // window.location.hash = "last_messsage";
    },
    subscribe () {
      let pusher = new Pusher('694554f85fddb79012b1', { cluster: 'ap1' })
      pusher.subscribe(this.kd_register)
      pusher.bind('pesan', data => {
        if (data.fs_kd_peg != this.user.fs_kd_peg) {
            this.show=true
            this.badgePesan=true
        }else{
            this.show=false
            this.badgePesan=false
        }
        this.$store.dispatch("addPesan", data);
        this.scrollToEnd()
      })
    },
    showPesanBaru(id){
      location.href = "#pesan_"+id;
      this.$store.dispatch("updateReadedPesans",{
        kd_register: this.kd_register,
      }).then(response => {
          if (response.error) {
            alert(response.message)
          } else {
            this.show=false
            this.badgePesan=false
          }
      }, error => {
        alert(error)
      })
    },
    dialogClose(){
      this.dialog= false
    },
    layananChange(){
       this.kd_register= '-'
      this.noRM= '-'
      this.penjamin= '-'
      this.noSep= '-'
      this.tglReg= '-'
      this.perawatan= '-'
      this.namaPasien= '-'
      this.check_siapklaim= false
      this.check_sudahklaim= false
      let ini = this
      for (const property in this.menu_klaim) {
        ini.menu_klaim.[property] = false
      }
    }
  },
};
</script>
